<template>
  <div class="item tw-flex" :class="selected ? 'active' : ''" @click="onSelect">
    <div class="item_avatar">
      <chat-window-avatar :src="room.avatarUrl"></chat-window-avatar>
    </div>
    <div class="item_message tw-flex-1 tw-flex tw-flex-col tw-justify-between">
      <p class="item_message_name tw-text-16px">
        {{room.mobile}}
      </p>
      <p
        v-if="lastMessage.type === 'text'"
        class="item_message_content tw-text-12px">
        {{lastMessage.text}}
      </p>
      <p
        v-if="lastMessage.type === 'image'"
        class="item_message_content tw-text-12px" >
        [图片]
      </p>
    </div>
  </div>
</template>

<script>
import chatWindowAvatar from './chat-window-avatar'

export default {
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    room: Object
  },
  components: {
    chatWindowAvatar
  },
  computed: {
    lastMessage () {
      return this.room.data
    }
  },
  methods: {
    onSelect () {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 280px;
  height: 80px;
  padding: 15px 10px;
  background-color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #EBF3FF, $alpha: 0.5);
  }
  &.active {
    background-color: #EBF3FF;
  }
  &_avatar {
    flex: none;
    width: 50px;
    height: 50px;
    background-color:#ffffff;
    border-radius: 2px;
    overflow: hidden;
  }
  &_message {
    margin-left: 10px;
    overflow: hidden;
    &_name {
      color: #303133;
      line-height: 22px;
    }
    &_content {
      color: #909399;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
