<template>
  <div class="window">
    <div class="window_left tw-flex tw-flex-col">
      <div class="window_left_list tw-flex-1">
        <vue-scroll v-if="rooms.length > 0">
          <chat-window-item
            v-for="(item, index) in rooms"
            :key="index"
            :selected="roomSelect === item.id"
            :room="item"
            @select="selectRoom(item.id)">
          </chat-window-item>
        </vue-scroll>
        <div v-else class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
          <p class="empty_tips">暂无沟通列表</p>
        </div>
      </div>
    </div>
    <div class="window_right tw-flex tw-flex-col">
      <template v-if="roomSelect">
        <chat-window-item-user :room="room"></chat-window-item-user>
        <div class="window_right_main">
          <vue-scroll ref="vs">
            <chat-window-item-content :messages="chatObj[roomSelect]"></chat-window-item-content>
          </vue-scroll>
        </div>
        <chat-window-input @send-message="sendMessage"></chat-window-input>
      </template>
      <div v-else class="tw-flex-1 tw-flex tw-items-center tw-justify-center">
        <p class="empty_tips">未选择沟通对象</p>
      </div>
    </div>
  </div>
</template>

<script>
import chatWindowItem from './chat-window-item'
import chatWindowItemUser from './chat-window-item-user'
import chatWindowItemContent from './chat-window-item-content'
import chatWindowInput from './chat-window-input'

import cookie from '@/configs/cookie'

const socketBaseUrl = process.env.VUE_APP_SOCKET_BASE_URL

let socket = null

export default {
  components: {
    // chatWindowUser,
    chatWindowItem,
    chatWindowItemUser,
    chatWindowItemContent,
    chatWindowInput
  },
  props: {
    // 卖家信息
    seller: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      rooms: [], // 房间列表
      roomSelect: '', // 当前房间
      chatObj: {} // 所有记录
    }
  },
  computed: {
    // 当前选择的房间
    room () {
      let result = {}
      this.rooms.forEach(element => {
        if (this.roomSelect === element.id) {
          result = element
        }
      })
      return result
    },
    // 卖家Id
    sellerId () {
      return this.seller.id || ''
    }
  },
  mounted () {
    this.initSocket()
  },
  beforeDestroy () {
    if (socket.readyState === WebSocket.OPEN) {
      socket.close()
    }
  },
  methods: {
    initSocket () {
      const token = cookie.get(this.globalVar.WEB_TOKEN)
      if (socket) socket = null
      socket = new WebSocket(`${socketBaseUrl}chat?token=${token}`)

      socket.onopen = () => {
        console.log('ws onopen')

        this.sendSocket('event_chatList')
      }

      socket.onmessage = (e) => {
        const res = JSON.parse(e.data)
        console.log('ws onmessage data:', res)

        let hasSellerId = false
        switch (res.eventName) {
          // 聊天列表
          case 'event_chatList':
            this.rooms = res.data.chatList
            if (this.roomSelect) {
              this.selectRoom(this.roomSelect)
            // 如果卖家id存在的话 则选择卖家Id
            } else if (this.sellerId) {
              /**
               * 与当前服务的发布者没有聊天记录时
               * 需在前端拼一个结构放在聊天列表中
               * 以供选择发送聊天
               */
              this.rooms.forEach(element => {
                if (element.id === this.sellerId) {
                  this.selectRoom(this.sellerId)
                  hasSellerId = true
                }
              })

              if (!hasSellerId) {
                this.rooms.unshift({
                  id: this.sellerId,
                  avatarUrl: this.seller.avatarUrl,
                  mobile: this.seller.mobile,
                  data: {
                    type: ''
                  }
                })

                this.selectRoom(this.rooms[0].id)
              }
            } else if (this.rooms.length > 0) {
              this.selectRoom(this.rooms[0].id)
            }
            break
          // 聊天记录
          case 'event_content':
            this.$set(this.chatObj, this.roomSelect, res.data.contentList)
            this.$nextTick(() => {
              this.$refs.vs.scrollTo({
                y: '200%'
              }, 500)
            })
            break
          // 单次消息
          case 'event_chat':
            this.sendSocket('event_chatList')
            if (this.roomSelect === res.data.content.fromId || this.roomSelect === res.data.content.toId) {
              // 新消息是当前选择的聊天
              if (!Object.hasOwnProperty.call(this.chatObj, this.roomSelect)) {
                this.$set(this.chatObj, this.roomSelect, [])
              }
              this.chatObj[this.roomSelect].push({
                ...res.data.content
              })
              this.$refs.vs.scrollTo({
                y: '200%'
              }, 500)
            }
            break
        }
      }

      socket.onclose = (e) => {
        console.log('ws onclose', e)
      }

      socket.onerror = (e) => {
        console.log('ws onerror', e)
        this.$message.error('连接出错, 请刷新')
      }
    },
    sendSocket (eventName, data = {}) {
      socket.send(JSON.stringify({ eventName, ...data }))
    },
    // 选对话
    selectRoom (userId) {
      if (userId) {
        this.roomSelect = userId
        this.sendSocket('event_content', {
          targetId: userId
        })
      }
    },
    // 发消息
    sendMessage (message) {
      this.sendSocket('event_chat', {
        targetId: this.roomSelect,
        data: {
          content: { ...message }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.window {
  flex: 1;
  max-width: 1460px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  &_left {
    flex: none;
    width: 280px;
    height: 100%;
    border-right: 1px solid #DCDFE6;
    &_list {
      overflow: auto;
    }
  }
  &_right {
    flex: 1;
    &_main {
      flex: 1;
      overflow: auto;
      background-color: #F8F9FA;
    }
  }
}
.empty_tips {
  color: #909399;
  text-align: center;
  padding: 20px 0;
}
</style>
