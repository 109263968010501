<template>
  <div class="chat tw-flex">
    <div class="chat_main">
      <chat-window
        :seller="seller">
      </chat-window>
    </div>
  </div>
</template>

<script>
import chatWindow from './chat-window'

export default {
  computed: {
    sellerId () {
      const sellerId = this.$route.query.sellerId
      return sellerId ? decodeURIComponent(sellerId) : ''
    },
    sellerAvatar () {
      const sellerAvatar = this.$route.query.sellerAvatar
      return sellerAvatar ? decodeURIComponent(sellerAvatar) : ''
    },
    sellerMobile () {
      const sellerMobile = this.$route.query.sellerMobile
      return sellerMobile ? decodeURIComponent(sellerMobile) : ''
    },
    // 卖家信息 (打开聊天窗口要进行聊天的对象)
    seller () {
      // 如果存在卖家的Id 则组成对象传入
      if (this.sellerId) {
        return {
          id: this.sellerId,
          avatarUrl: this.sellerAvatar,
          mobile: this.sellerMobile
        }
      }
      return {}
    }
  },
  components: {
    chatWindow
  }
}
</script>

<style lang="scss" scoped>
.chat {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 1100px;
  background-image:url('../../assets/images/chat_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  .chat_main {
    display: flex;
    width: 903px;
    height: 784px;
    margin: 0 auto;
  }
}
</style>
