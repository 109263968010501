<template>
  <div class="content_wrapper">
    <div
      class="content tw-flex "
      :class="{
        'tw-flex-row-reverse': isUser(item.fromId)
      }"
      v-for="(item, index) in messages"
      :key="index">
      <div
        class="content_avatar"
        :style="{
          marginLeft: isUser(item.fromId) ? '10px' : '0',
          marginRight: isUser(item.fromId) ? '0' : '10px'
        }">
        <chat-window-avatar :src="item.fromAvatarUrl || ''"></chat-window-avatar>
      </div>
      <div
        class="content_text"
        v-if="item.data.type === 'text'">
        <p class="tw-text-14px">
          {{item.data.text}}
        </p>
      </div>
      <div
        class="content_image"
        v-if="item.data.type === 'image'">
        <el-image
          :src="item.data.image"
          alt=""
          :preview-src-list="[item.data.image]"
          @load="onImageLoad">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import chatWindowAvatar from './chat-window-avatar'

export default {
  components: {
    chatWindowAvatar
  },
  props: {
    messages: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    },
    isUser () {
      return (id) => {
        return this.userInfo.id === id
      }
    }
  },
  methods: {
    onImageLoad (e) {
      let imageWidth = 0
      let imageHight = 0

      const { width, height } = e.target
      const aspect = width / height
      if (aspect < 1 / 2) {
        imageWidth = 70
        imageHight = imageWidth / aspect
      } else if (aspect < 1) {
        imageHight = 140
        imageWidth = imageHight * aspect
      } else if (aspect < 2) {
        imageWidth = 140
        imageHight = imageWidth / aspect
      } else {
        imageHight = 70
        imageWidth = imageHight * aspect
      }
      e.target.style.width = imageWidth + 'px'
      e.target.style.height = imageHight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  padding: 20px 30px;
}
.content {
  margin-bottom: 20px;
  &_avatar {
    width: 30px;
    height: 30px;
    border-radius: 2px;
  }
  &_text {
    max-width: 220px;
    padding: 12px 10px;
    background: #EBF3FF;
    color: #303133;
    border-radius: 2px;
    & > p {
      word-break: break-all;
    }
  }
}
</style>
