<template>
  <img v-if="src" class="img" :src="src" style="object-fit: cover;" alt="">
  <img v-else class="img" src="../../../assets/images/default_avatar.png" alt="">
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  height: 100%;
}
</style>
