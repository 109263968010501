<template>
  <div class="input">
    <div class="input_header tw-flex tw-items-center">
      <image-upload @input="sendImage" />
    </div>
    <div class="input_main">
      <vue-scroll>
        <el-input
          class="my-el-input-no-border tw-text-14px"
          v-model="text"
          type="textarea" maxlength="300" show-word-limit
          resize="none"
          :autosize="{
            minRows: 4
          }">
        </el-input>
      </vue-scroll>
      <div class="input_main_btn">
        <el-button size="small" @click="sendText">发送消息</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import imageUpload from './image-upload'

export default {
  components: {
    imageUpload
  },
  data () {
    return {
      text: ''
    }
  },
  methods: {
    sendText () {
      if (!this.text) return

      this.$emit('send-message', {
        type: 'text',
        text: this.text
      })
      this.text = ''
    },
    sendImage (url) {
      this.$emit('send-message', {
        type: 'image',
        image: url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  background: #FFFFFF;
  &_header {
    height: 40px;
    padding: 0 16px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  }
  &_main {
    position: relative;
    height: 131px;
    overflow: auto;
    &_btn {
      position: absolute;
      right: 20px;
      bottom: 4px;
    }
  }
}
</style>
