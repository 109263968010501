<template>
  <div class="user tw-flex tw-items-center">
    <div class="user_avatar">
      <chat-window-avatar :src="room.avatarUrl" />
    </div>
    <p class="user_name tw-text-14px">{{room.mobile}}</p>
    <!-- <p class="user_role tw-text-12px">技术经纪人</p> -->
    <!-- <i class="user_icon iconfont iconjingjiren" /> -->
  </div>
</template>

<script>
import chatWindowAvatar from './chat-window-avatar'

export default {
  components: {
    chatWindowAvatar
  },
  props: {
    room: Object
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.user {
  height: 64px;
  padding: 0 20px;
  background-color: #ffffff;
  &_avatar {
    background-color:#ffffff;
    width: 44px;
    height: 44px;
    border-radius: 2px;
    overflow: hidden;
  }
  &_name {
    margin-left: 20px;
    color: #303133;
    line-height: 20px;
  }
  &_role {
    margin-left: 20px;
    color: #909399;
    line-height: 17px;
  }
  &_icon {
    margin-left: 10px;
    color: #3473E6;
  }
}
</style>
