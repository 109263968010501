<template>
  <div class="upload">
    <i class="upload_icon iconfont icontupian" @click="onClick" />
    <input ref="upload" type="file" accept="image/png, image/jpg, image/jpeg, image/gif" style="display: none;" @change="onChange" />
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  methods: {
    onClick () {
      this.$refs.upload.value = ''
      this.$refs.upload.click()
    },
    onChange (e) {
      const file = e.target.files[0]
      const accept = this.$refs.upload.accept
      if (!accept.includes(file.type)) {
        this.$message.warning('文件类型不符合要求')
        return
      }
      api.uploadFile({
        file,
        tag: 'file',
        accept: this.$refs.upload.accept
      }).then(res => {
        if (res.data.code === 0) {
          this.$emit('input', res.data.data.url)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('上传出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    border-radius: 50%;
    background-color: #EBF3FF;
    color: $color-primary;
  }
}
</style>
